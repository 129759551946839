<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>ข้อตกลงความเป็นส่วนตัว</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/mekongdevelopmentbank/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Chúng tôi, MEKONG DEVELOPMENT COMMERCIAL JOINT STOCK BANK, đã phát triển các sản phẩm và dịch vụ của mình với sự quan tâm đến quyền riêng tư của bạn kể từ khi thành lập. Chính sách quyền riêng tư này giải thích cách chúng tôi thu thập nó. Sử dụng và chia sẻ thông tin thông qua ứng dụng có tên MEKONG Mobile ("Ứng dụng") Chúng tôi cung cấp cho bạn Chính sách quyền riêng tư này để chia sẻ các bước chúng tôi thực hiện nhằm đảm bảo tính bảo mật thông tin của bạn khi bạn sử dụng Ứng dụng. Chính sách quyền riêng tư này mô tả các loại thông tin chúng tôi thu thập khi bạn sử dụng Ứng dụng. Cách chúng tôi sử dụng và bảo vệ thông tin này Chúng tôi lưu giữ thông tin trong bao lâu và chúng tôi chia sẻ thông tin đó với ai. Thông tin này cũng giải thích các quyền và tùy chọn quyền riêng tư của bạn. Bằng cách sử dụng Ứng dụng, bạn đồng ý với việc thu thập, sử dụng và chia sẻ thông tin của mình như được mô tả trong Chính sách quyền riêng tư này.
MEKONG Mobile là một ứng dụng vay mượn trực tuyến đáp ứng nhu cầu tiêu thụ hàng ngày và vốn khẩn cấp của người việt nam, và nó cung cấp các sản phẩm cho vay đa dạng và hạn chót cho vay linh hoạt, và bạn chỉ cần vài phút để hoàn thành tất cả các quá trình và nhanh chóng nhận được số tiền bạn cần. Nếu bạn có một lịch sử tín dụng tốt, nó có thể cung cấp cho bạn một khoản vay hạn chế và hạn chót tốt hơn.
Tuyên bố trách nhiệm：MEKONG Mobile là một nền tảng dịch vụ cho vay bên thứ ba, không tham gia trực tiếp vào vay mượn, chỉ cung cấp cho khách hàng một trải nghiệm và dịch vụ thuận tiện.
Thu thập thông tin cá nhân của bạn
Khi sử dụng Ứng dụng, bạn sẽ trực tiếp cung cấp cho chúng tôi thông tin cá nhân của mình thông qua các tương tác của bạn với Ứng dụng. Để chúng tôi có thể cung cấp cho bạn các dịch vụ cho vay phù hợp. Thông tin cá nhân mà chúng tôi thu thập thông qua các điều khoản hoạt động của bạn có thể bao gồm: (1) tên đầy đủ, (2) ngày sinh, (3) giới tính, (4) địa chỉ cư trú, (5) số điện thoại, (6) thông tin liên hệ khẩn cấp (7) ) ) ) Thông tin nhận dạng (8) Thông tin tài khoản ngân hàng của người nhận thanh toán (9) Thông tin sinh trắc học
Chúng tôi cũng có thể tự động thu thập thông tin từ thiết bị của bạn ở chế độ nền. Thông tin và quyền chúng tôi thu thập bao gồm:
(1) Vị trí: Khi ứng dụng đang chạy ở nền trước, chúng tôi thu thập thông tin vị trí địa lý gần đúng của bạn để tự động điền thông tin địa chỉ của bạn.
(2) Thông tin ứng dụng đã cài đặt: Chúng tôi có thể đọc dữ liệu ứng dụng đã cài đặt của bạn nhằm mục đích phân tích rủi ro và đề xuất sản phẩm.
(3) Máy ảnh: Chúng tôi cần bạn cho phép sử dụng máy ảnh để chụp ảnh khuôn mặt của bạn và các tài liệu KYC cần thiết.
(4) Nhận dạng khuôn mặt: Để cung cấp các dịch vụ an toàn và thuận tiện hơn, MEKONG Mobile sẽ sử dụng công nghệ nhận dạng khuôn mặt để xác minh danh tính. Chúng tôi sẽ bảo vệ nghiêm ngặt quyền riêng tư của bạn. Tất cả dữ liệu khuôn mặt đều được mã hóa và chỉ được sử dụng để ngăn chặn gian lận, tăng tốc độ đăng ký và đăng nhập, đồng thời đảm bảo an toàn giao dịch. Nó sẽ không được sử dụng cho các mục đích khác mà không có sự cho phép của bạn.
(5) Đầu mối liên hệ để cải thiện quy trình đăng ký khoản vay. Người dùng có thể chọn danh bạ khẩn cấp từ danh sách liên lạc trên điện thoại của mình. Điều này có nghĩa là chúng tôi thu thập thông tin liên hệ của người dùng nhằm mục đích tự động điền vào các mẫu đơn đăng ký. Chúng tôi cũng có thể phân tích thông tin này nhằm mục đích đánh giá rủi ro, chẳng hạn như xác định mức độ tin cậy hoặc khả năng đủ điều kiện của người dùng đối với một số sản phẩm hoặc dịch vụ nhất định. Chúng tôi cũng có thể sử dụng thông tin liên hệ để xác minh danh tính của người dùng. Ngăn chặn gian lận và các hoạt động bất hợp pháp khác và phân tích hành vi của khách hàng. Xin lưu ý rằng chúng tôi có thể liên hệ với bạn qua điện thoại vì những mục đích này. Quay số bằng cách quay số tự động hoặc tin nhắn được ghi âm trước
(6)Thông tin điện thoại khác Chúng tôi thu thập thông tin thiết bị để giúp chúng tôi xác định hồ sơ người dùng của bạn và đề xuất các sản phẩm phù hợp. Điều này bao gồm thương hiệu thiết bị, kiểu máy và phiên bản hệ điều hành. Môi trường mạng thiết bị WiFi, trạng thái thiết bị, thông tin phần mềm ứng dụng, thông tin bộ nhớ thiết bị
Việc sử dụng/mục đích thông tin của bạn
Dữ liệu cá nhân sẽ được xử lý, thu thập, sử dụng, tiết lộ, lưu trữ và lưu giữ cho các mục đích sau:
• Xác định người dùng;
• Liên hệ với người dùng về việc đăng ký tài khoản hoặc thông tin được yêu cầu.
• Đăng ký tài khoản và xác định hoặc hỗ trợ đưa ra quyết định trong quá trình sàng lọc nhà đầu tư, người vay, đăng ký hoặc đơn xin vay. và/hoặc cung cấp sản phẩm và/hoặc dịch vụ của chúng tôi cho người dùng và mọi mục đích liên quan trực tiếp;
• Tạo điều kiện thuận lợi cho việc đăng ký khoản vay, xác minh, theo dõi, giải ngân, thu nợ và các quy trình khác. và các hành động khắc phục khác
• Duy trì hồ sơ nội bộ;
• Tiếp thị và cung cấp các sản phẩm và dịch vụ mới. Có liên quan hoặc bổ sung cho người dùng;
• Tiến hành kiểm tra lý lịch và xác minh danh tính;
• Tiến hành và thực hiện giám sát, phân tích và ngăn chặn gian lận;
• Ngăn chặn, phát hiện và điều tra hành vi phạm tội thực sự hoặc bị nghi ngờ. Phân tích và quản lý rủi ro kinh doanh.
• Thực hiện phân tích và theo dõi dữ liệu.
• Tiến hành nghiên cứu thị trường để ứng dụng hiểu và xác định vị trí, sở thích và nhân khẩu học của khách hàng. Phát triển các ưu đãi, sản phẩm hoặc chương trình tiếp thị đặc biệt liên quan đến sản phẩm và dịch vụ. và để cải thiện (chẳng hạn như tùy chỉnh các ưu đãi và sản phẩm) các sản phẩm và dịch vụ đó;
• Tiến hành nghiên cứu dữ liệu và điều tra các tranh chấp, thanh toán và các hoạt động bị nghi ngờ là bất hợp pháp. hoặc gian lận;
• Tiến hành các hoạt động tiếp thị và quảng cáo liên quan đến công ty chúng tôi. Các chi nhánh địa phương của Công ty, đối tác thương mại của công ty và các sản phẩm và dịch vụ của các công ty khác, cho dù dưới dạng điện tử qua email và bài đăng hoặc qua điện thoại, SMS hoặc phương tiện truyền thông xã hội hoặc bằng cách gửi bản tin (ví dụ: để cho người dùng biết về các sản phẩm hoặc tính năng mới) cũng như sự kiện khuyến mại, cuộc thi, trình diễn, chương trình tích điểm và các sự kiện khác do Công ty và/hoặc Công ty tổ chức), bao gồm cả việc sử dụng các phương tiện tự động. Những thông tin liên lạc như vậy sẽ liên quan đến các sản phẩm và dịch vụ tài chính. Dịch vụ vv.
• Phát triển, cải tiến và duy trì các quy trình và mô hình đánh giá rủi ro. Ngoại tuyến và trực tuyến
• Phát triển và tạo điểm tín dụng, mô hình tín dụng và mô hình người dùng.
• Đăng ký/tham gia cùng người dùng trong nhóm tín dụng và/hoặc bảo hiểm nhân thọ. Phân tích rủi ro tín dụng Tín dụng kiểm tra điểm tín dụng và thu thập điểm của người dùng;
• Tìm kiếm vị trí của người dùng;
• Vận hành, giám sát, quản lý và phân tích ứng dụng. Phát triển, cải thiện và nâng cấp các tính năng, chức năng, sản phẩm và dịch vụ trong Ứng dụng. Bao gồm chương trình điểm khuyến mại và phần thưởng
• Trả lời các câu hỏi và yêu cầu của người dùng;
• Tạo điều kiện thuận lợi cho các giao dịch liên quan đến tài sản kinh doanh. (Điều này có thể mở rộng đến việc sáp nhập và mua lại mua hoặc bán tài sản) Công ty
• Tạo điều kiện và có thể được yêu cầu thực hiện điều đó như một phần của hoạt động tài chính hoặc cấp vốn (chẳng hạn như vay tiền, hạn mức tín dụng, thế chấp, chuyển nhượng, chuyển nhượng như một hình thức bảo hiểm hoặc cho vay thế chấp)
• Bắt đầu và duy trì liên lạc với người dùng qua email (email), SMS (Dịch vụ tin nhắn ngắn), nhắn tin/liên lạc trong ứng dụng, thư đã đăng ký, cuộc gọi điện thoại hoặc truy cập trang web;
• Giải quyết khiếu nại và quản lý các yêu cầu, thắc mắc;
• Theo dõi hoặc ghi lại các cuộc trò chuyện qua điện thoại và tương tác của khách hàng nhằm mục đích đảm bảo chất lượng. Đào tạo nhân viên và đánh giá hiệu suất cũng như xác minh danh tính
• Cung cấp thông tin cho các bên bên ngoài như cơ quan chính phủ. và các tổ chức khác như một phần của nghiên cứu và/hoặc nghiên cứu khoa học; và
• Cho các mục đích pháp lý và/hoặc quy định khác. (bao gồm nhưng không giới hạn ở việc nhận tư vấn pháp lý và giải quyết tranh chấp) cũng như các mục đích kinh doanh hợp pháp và thực hiện các nhiệm vụ hành chính của hoạt động và theo tất cả các luật hiện hành
Chia sẻ thông tin cá nhân của bạn
Chúng tôi sẽ thực hiện mọi biện pháp phòng ngừa để đảm bảo rằng thông tin cá nhân của bạn không bị chia sẻ mà không có sự cho phép của bạn. Chúng tôi có thể chia sẻ thông tin cá nhân của bạn liên quan đến các vấn đề chủ quan sau đây trong phạm vi được pháp luật hiện hành cho phép:
(1) Nhân viên được ủy quyền của Công ty và các công ty liên kết bao gồm kiểm toán viên của Công ty hoặc kiểm toán viên bên ngoài. Các hợp tác xã tài chính và/hoặc bên thứ ba liên kết với công ty chúng tôi.
(2) Thông tin tín dụng (một loại cơ quan báo cáo tín dụng)
(3) Các quan chức chính phủ tiến hành điều tra hoặc truy tố pháp lý.
(4) Các tổ chức hợp tác bên thứ ba bao gồm người cho vay vốn, đối tác sau khi thanh toán khoản vay đối tác, nhà cung cấp dịch vụ xác minh bên thứ ba, nhà cung cấp tin nhắn và đối tác dịch vụ cuộc gọi.
(5) Được sử dụng cùng với các vấn đề pháp lý khác. Lệnh của chính phủ hoặc yêu cầu chính thức
Dữ liệu sinh trắc học
(1) Đảm bảo danh tính chính xác và ngăn chặn gian lận tín dụng. Trong quá trình nhận dạng khuôn mặt, chúng tôi có thể yêu cầu bạn gửi ảnh trực tiếp về khuôn mặt của bạn. Dữ liệu khuôn mặt của bạn sẽ chỉ được thu thập và xử lý cho các mục đích sau: để xác minh danh tính của bạn và chứng minh rằng bạn đã khởi tạo đơn xin vay tiền. Và để ngăn chặn hoạt động tín dụng gian lận, thông tin này có thể được tham chiếu bất cứ lúc nào. Điều này bao gồm các tranh chấp hoặc điều tra liên quan đến khoản vay.
(2) Khi quá trình nhận dạng khuôn mặt hoàn tất. Dữ liệu ảnh chụp của bạn sẽ được tải lên máy chủ của chúng tôi. Thông tin này được lưu giữ cho đến khi khoản vay và mọi khoản thanh toán còn lại được hoàn trả đầy đủ. Chúng tôi cũng có thể được yêu cầu lưu giữ thông tin này trong thời gian dài hơn để tuân thủ luật pháp và quy định hiện hành. BAO GỒM CÁC HẠN CHẾ PHÁP LÝ Nếu bạn muốn xóa dữ liệu ảnh chụp của mình ngay lập tức, vui lòng liên hệ với chúng tôi qua email tại market@mekongdevelopmentbank.com
(3) Chúng tôi rất coi trọng việc bảo vệ quyền riêng tư của bạn và sẽ không tiết lộ ảnh khuôn mặt của bạn cho bên thứ ba. Trừ khi luật hiện hành yêu cầu chúng tôi tiết lộ thông tin này hoặc làm bằng chứng trong tranh chấp liên quan đến tín dụng của bạn. Điều này có thể bao gồm việc cung cấp thông tin cho tòa án. Dữ liệu của bạn có thể được tòa án hoặc cơ quan có thẩm quyền lưu giữ trong khoảng thời gian do pháp luật quy định nếu cần thiết theo lệnh của tòa án hoặc theo ủy quyền của cơ quan chính phủ hoặc cơ quan được pháp luật hiện hành ủy quyền.
Bảo vệ thông tin của bạn
Đảm bảo tính bảo mật của việc truyền dữ liệu Chúng tôi mã hóa dữ liệu trên và truyền nó đến máy chủ của chúng tôi. Chúng tôi sẽ lưu giữ thông tin của bạn thông qua Ứng dụng trong khoảng thời gian pháp luật yêu cầu và chỉ cho mục đích thu thập và sử dụng thông tin đó. Nếu muốn xóa dữ liệu của mình ngay lập tức, bạn có thể liên hệ với Trung tâm Dịch vụ Khách hàng của chúng tôi bằng cách gửi email đến market@mekongdevelopmentbank.com
Nghĩa vụ của người dùng của bạn
Bạn đồng ý thông báo cho chúng tôi ngay lập tức và trong mọi trường hợp trong vòng 14 ngày dương lịch bằng văn bản về bất kỳ thay đổi nào đối với thông tin bạn cung cấp cho chúng tôi tùy từng thời điểm. và sẽ nhanh chóng đáp ứng bất kỳ yêu cầu nào của chúng tôi.
Ở đâu:
(1) Bạn không được cung cấp ngay cho chúng tôi bất kỳ thông tin người dùng hoặc thông tin cá nhân nào mà chúng tôi yêu cầu một cách hợp lý.
(2) bạn từ chối hoặc rút lại bất kỳ sự đồng ý nào mà chúng tôi có thể cần để xử lý, chuyển giao hoặc tiết lộ thông tin cá nhân cho các mục đích được nêu ở trên trong Chính sách quyền riêng tư này (ngoài các mục đích liên quan đến tiếp thị hoặc quảng bá sản phẩm và dịch vụ cho bạn) và/hoặc
(3) Chúng tôi hoặc các thành viên trong công ty của chúng tôi có liên quan đến tội phạm tài chính hoặc rủi ro liên quan.
Chúng ta có thể:
(1) Không thể cung cấp cho bạn các dịch vụ mới hoặc tiếp tục cung cấp cho bạn tất cả hoặc một phần dịch vụ. và có quyền chấm dứt mối quan hệ của chúng tôi với bạn.
(2) thực hiện các hành động cần thiết để chúng tôi hoặc các thành viên của chúng tôi tuân thủ các yêu cầu hoặc nghĩa vụ pháp lý;
(3) Đình chỉ, chuyển nhượng hoặc đóng tài khoản của bạn khi được luật pháp địa phương cho phép;
Tính chính xác và chính xác của thông tin đã gửi
(1) Gửi thông tin đúng và chính xác – Bạn phải gửi thông tin đúng, chính xác và không gây hiểu lầm cho chúng tôi, bao gồm cả thông tin cá nhân. Bạn phải cập nhật kịp thời thông tin này và thông báo cho chúng tôi về bất kỳ thay đổi nào càng sớm càng tốt. Chúng tôi có quyền yêu cầu tài liệu để xác minh thông tin bạn cung cấp.
(2) Tự nguyện gửi thông tin cá nhân – Chúng tôi chỉ có thể thu thập thông tin cá nhân của bạn nếu bạn tự nguyện gửi thông tin đó cho chúng tôi. Nếu bạn chọn không gửi thông tin cá nhân của mình cho chúng tôi hoặc sau đó rút lại sự đồng ý của bạn đối với việc chúng tôi sử dụng thông tin cá nhân của bạn. Chúng tôi có thể không cung cấp được cho bạn một số hoặc tất cả các dịch vụ có sẵn thông qua Ứng dụng.
Quyền dữ liệu của bạn
Bạn có thể thực hiện các quyền của mình theo luật hiện hành. Chi tiết như sau:
(1) Quyền truy cập thông tin cá nhân của bạn
(2) Quyền rút lại sự đồng ý của bạn đối với chúng tôi bất cứ lúc nào theo cách thức và phương pháp do bạn chỉ định.
(3) Quyền sửa thông tin cá nhân không chính xác của bạn và cập nhật thông tin cá nhân không đầy đủ của bạn.
Việc thực hiện các quyền đó phải được thực hiện bằng văn bản gửi đến địa chỉ email sau: market@mekongdevelopmentbank.com. Chúng tôi sẽ đưa ra quyết định được phê duyệt sau khi đơn đăng ký được gửi dựa trên các điều kiện trên.
Sửa đổi
Đôi khi, chúng tôi có thể thay đổi hoặc cập nhật Chính sách quyền riêng tư này. Chúng tôi sẽ thông báo cho bạn về các phiên bản cập nhật sớm nhất có thể. Bạn có thể xem phiên bản mới nhất của Chính sách quyền riêng tư này trên Ứng dụng.
Làm thế nào để liên hệ với chúng tôi?
Nếu bạn có bất kỳ câu hỏi nào hoặc muốn biết thêm chi tiết về chính sách của chúng tôi hoặc muốn thực hiện các quyền của mình, vui lòng liên hệ với chúng tôi theo địa chỉ market@mekongdevelopmentbank.com, bao gồm các thông tin sau:
• Tên và tên tài khoản của bạn;
• Ngày giờ của các giao dịch hoặc thay đổi đó.
• Mô tả lỗi và mã tham chiếu (nếu có).
• Tên và địa chỉ liên lạc. Bao gồm email (nếu có)
Mọi lỗi được báo cáo hoặc hoạt động bất thường sẽ được điều tra ngay lập tức. Chúng tôi sẽ cho bạn biết kết quả sớm nhất có thể.
Liên Hệ:
Email: market@mekongdevelopmentbank.com
Địa chỉ: 248 Tran Hung Dao St., My Xuyen Ward, Long Xuyen City, Long Xuyen
ĐT: +84 534275976`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: "";
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  transform: scale(90%);
  margin: -240px auto 0;
  height: 4500px;
  /* box-shadow:0 6px 14px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
.img1{
  /* margin-top: 50px;
  padding: 0 200px; */
}
</style>